angular.module('cruiser').controller('WideModalCtrl', WideModalCtrl)

WideModalCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$controller',
    'config',
    'backOrderService',
    'StockManagementService',
    '$timeout',
    'Parts'
]

function WideModalCtrl($scope, $uibModalInstance, $controller, config, backOrderService, StockManagementService,
                        $timeout, Parts) {
    backOrderService.getBrands().then(data => $scope.brands = data.brands)
    StockManagementService.tags().then(data => $scope.tags = data)
    $scope.opt = {}

    $scope.setBrand = item => {
        $scope.data.brand = item
    }

    $scope.removeTag = index => {
        $scope.data.tags.splice(index, 1);
    }

    $scope.addTag = item => {
        if ($scope.data.tags.find(itemT => itemT.id === item.id) === undefined) {
            $scope.data.tags.push(item);
        }
        $scope.tag = ''
    }

    $scope.addNewTag = () => {
        !$scope.data.tags ? $scope.data.tags = []:null;
        $scope.data.tags.push({
            id: -1,
            description: $scope.data && $scope.data.tag
        });
        $scope.data ? $scope.data.tag = '' : null;
    }

    $scope.activateTab = index => {
        $scope.activePill = index;
    }

    // it's used in bundle search
    $scope.partNumberAutocomplete = val =>
        backOrderService.findPartNumbers({
            search: val,
        }).then(data => data.data)

    $scope.setPartNumber = item => {
        $scope.BundlePartNumber = item.PartNumber;
        $scope.BundleDescriptionEnglish = item.DescriptionEnglish
        $scope.BundleBrandName = item.part.brand.BrandName
    }


    // bundle tables functions
    $scope.resetBundleItems = function () {
        $scope.$emit('bundleItems:clear');

        $scope.bItems = [];
        for (let i = 0; i < 10; i++) {
            $scope.addEmptyItem();
        }
    };

    $scope.addEmptyItem = function () {
        $scope.bItems.push({PartNumber: '', description: '', qty: 0});
    };

    $scope.removePart = index => {
        //possibly triggered by hotkey
        if (typeof index !== "number") {
            index = $(document.activeElement).parents('.part-info').data('index');

            // hotkey triggered without focused part info, nothing to delete
            if (typeof index !== "number") {
                return;
            }
        }

        $scope.bItems.splice(index, 1);
    };

    $scope.saveBundleItems = () => {
        $scope.data.bundles = []
        const currentCount = [];
        $scope.bItems.forEach(item => {
            // if (item.id) {
                $scope.data.bundles.push(item)
                currentCount.push(Math.trunc(item.stockca ? item.stockca.Stock_Qty : item.Stock_Qty/ (item.Qty || 1)))
            // }
        })
        $scope.bItems = []
        $scope.data.currentCount = Math.min(...currentCount);
        $scope.activateTab(0)
    }

    $scope.cancelBundleEdit = () => {
        $scope.bItems = []
        $scope.activateTab(0)
    }

    $scope.editBundleItems = () => {
        $scope.bItems = [];
        $scope.data.bundles.forEach(item => {
            item.id && $scope.bItems.push(item)
        })
        $scope.activateTab(1)
    }

    $scope.resetBundleItems()

    $scope.processHotkey = function ($event) {
        let keyCode = $event.which;

        if ([9, 13, 46, 187].indexOf(keyCode) > -1) {
            $event.preventDefault();
        }

        // tab
        if (keyCode === 9) {
            return iterateOverInputs($event.target);
        }

        // ctrl + enter optionally + shift
        if (keyCode === 13 && $event.originalEvent.getModifierState('Control')) {
            let shiftIsPressed = $event.originalEvent.getModifierState('Shift');
            return iterateOverRows($event.target, shiftIsPressed);
        }

        // enter
        if (keyCode === 13) {
            return $scope.runSearch()
        }

        // shift + del
        if (keyCode === 46 && $event.originalEvent.getModifierState('Shift')) {
            return $scope.removePart();
        }

        // shift + =
        if (keyCode === 187 && $event.originalEvent.getModifierState('Shift')) {
            return $scope.addEmptyPart();
        }
    }

    $scope.parsePaste = async function (data, index, type) {
        let parsedInput = await parsePastedInput(data, type);
        parsedInput = angular.isArray(parsedInput) ? parsedInput : [parsedInput];

        if ($scope.bItems) {
            let partIndex = index;

            for (let parsedInputIndex = 0; parsedInputIndex < parsedInput.length; parsedInputIndex++) {
                $scope.bItems[partIndex] = $scope.bItems[partIndex] || parsedInput[parsedInputIndex];

                $scope.bItems[partIndex].DescriptionEnglish = parsedInput[parsedInputIndex].DescriptionEnglish || $scope.bItems[partIndex].DescriptionEnglish;
                $scope.bItems[partIndex].PartNumber = parsedInput[parsedInputIndex].PartNumber || $scope.bItems[partIndex].PartNumber;
                $scope.bItems[partIndex].Qty = parsedInput[parsedInputIndex].Qty || $scope.bItems[partIndex].Qty;

                partIndex++;
            }
        } else {
            $scope.bItems = parsedInput;
        }

        fireSearch();
    };

    $scope.pasteProcess = function ($event, $index, type) {
        $event.stopPropagation();
        $event.stopImmediatePropagation();

        $timeout(function () {
            $scope.parsePaste(angular.element($event.target).val(), $index, type);
        }, 10);
    };

    $scope.runSearch = () => {
        $scope.bItems = $scope.bItems.map(p => {
            p.PartNumber = cleanse(p.PartNumber)

            return p;
        })

        fireSearch()
    }

    $scope.saveDialog = () => {
        $scope.data.clearPartNumber = cleanse($scope.data.partNumber)
        $scope.buttonClick('save')
    }


    $controller('SimpleModalCtrl', {
        $scope: $scope,
        $uibModalInstance: $uibModalInstance,
        config: config
    })

    function cleanse(partNumber) {
        if (!partNumber) {
            return '';
        }

        return partNumber.replace(/[-"']/g, '');
    }

    function fillPartInfo(existing, retrieved) {
        let fields = [
            'id',
            'Stock_Qty'
        ];

        fields.forEach(field => {
            if(field == 'id' && !existing.id){
                existing.part_id = retrieved ? retrieved.Part_id : '';
                existing.part = {};
                existing.part.brand = retrieved ? retrieved.brand : '';
                existing.description = retrieved ? retrieved.DescriptionEnglish : '';
                return;
            }
            existing[field] = retrieved && retrieved[field]
                ?
                retrieved[field]
                :
                existing[field] ? existing[field] : '';
        })
    }

    function iterateOverInputs($input) {

        const parentRow = $($input).parents('.part-info');
        const childs = parentRow.find('.input-only');
        const childsCnt = childs.length;

        const currentNum = $($input).data('index');
        const nextNum = currentNum % childsCnt + 1;

        $timeout(function () {
            $(parentRow.find('.input-only[data-index="' + nextNum + '"]')).focus();
        }, 0);
    }

    function iterateOverRows($cell, backward = false) {
        const $row = $($cell).parents('.part-info');

        const cellIndex = $($cell).data('index');
        const parentRow = $($row).parents('.main-info');
        const childs = parentRow.find('.part-info');
        const childsCnt = childs.length;

        const currentNum = $($row).data('index');
        let nextNum;

        if (backward) {
            nextNum = currentNum % childsCnt - 1;
        } else {
            nextNum = currentNum % childsCnt + 1;
        }

        $timeout(function () {
            $(parentRow.find('.part-info[data-index="' + nextNum + '"] *[data-index="' + cellIndex + '"]')).focus();
        }, 0);
    }

    async function parseInput(string, delimiter) {
        return Parts.csv({csv: string, delimiter: delimiter}).$promise.then(function (data) {
            return data;
        })
    }

    async function parsePastedInput(data, pasteInputType) {
        let delimiter = '';

        if (data.match(/[\t]/)) {
            delimiter = "\t";
        } else if (data.match(/"/)) {
            delimiter = ",";
        }

        let parsedParts = await parseInput(data, delimiter);

        return parsedParts.map(function (partParts) {
            switch (partParts.length) {
                // special csv format obtained from provider
                case 4: {
                    return {
                        DescriptionEnglish: partParts[0],
                        PartNumber: cleanse(partParts[2]),
                        Qty: parseInt(partParts[1])
                    };
                }

                case 3: {
                    return {
                        DescriptionEnglish: partParts[0],
                        PartNumber: cleanse(partParts[1]),
                        Qty: parseInt(partParts[2])
                    };
                }

                case 2: {
                    if (partParts[1].match(/^[0-9]{1,3}$/)) {
                        if (pasteInputType === 'desc') {
                            return {
                                DescriptionEnglish: partParts[0],
                                PartNumber: cleanse(partParts[1]),
                                Qty: 0
                            }
                        }

                        if (pasteInputType === 'part') {
                            return {
                                DescriptionEnglish: '',
                                PartNumber: cleanse(partParts[0]),
                                Qty: parseInt(partParts[1])
                            }
                        }
                    }

                    return {
                        DescriptionEnglish: partParts[0],
                        PartNumber: cleanse(partParts[1]),
                        Qty: 0
                    }
                }
                case 1: {
                    if (pasteInputType === 'desc') {
                        return {
                            DescriptionEnglish: partParts[0],
                            PartNumber: '',
                            Qty: 0
                        }
                    }

                    if (pasteInputType === 'part') {
                        return {
                            DescriptionEnglish: '',
                            PartNumber: cleanse(partParts[0]),
                            Qty: 0
                        }
                    }

                    if (pasteInputType === 'qty') {
                        return {
                            DescriptionEnglish: '',
                            PartNumber: '',
                            Qty: parseInt(partParts[0])
                        }
                    }
                }
            }
        })
    }

    function fireSearch() {
        // let uniqueNumbers = {};
        // $scope.bItems = $scope.bItems.map(function (p) {
        //     p.PartNumber = cleanse(p.PartNumber);
        //
        //     uniqueNumbers[p.PartNumber] = p;
        //
        //     return p;
        // });
        //
        // let uniquePartNumbers = Object.keys(uniqueNumbers);
        // let numbersUniqueToSearch = [];
        //
        // $scope.bItems.forEach(p => uniquePartNumbers.indexOf(p.PartNumber === -1) && numbersUniqueToSearch.push(p));
        //
        // $scope.bItems = numbersUniqueToSearch;
        //
        // if ($scope.overrideSearch && angular.isFunction($scope.overrideSearch)) {
        //     return $scope.overrideSearch(numbersUniqueToSearch);
        // }
        //
        // let numbersArrayToSearch = numbersUniqueToSearch.map(function (n) {
        //     return cleanse(n.PartNumber);
        // });
        //
        // // prevent multiple searches at a time
        // if ($scope.loading) {
        //     return;
        // }
        //
        // $scope.loading = true;
        // $scope.emptyInit = false;
        let numbersArrayToSearch = [...$scope.bItems].map(function (p) {
            return {
                PartNumber:p.PartNumber,
                BrandName: p.part && p.part.brand && p.part.brand.BrandName ? p.part.brand.BrandName : null
            }
        });
        StockManagementService.searchItems({
            numbers: numbersArrayToSearch
        }).then(data => {
            $scope.bItems.forEach((part, index) => {
                let obtainedPart = data && data[index] ? data[index] : null ;
                part.DescriptionEnglish = obtainedPart && obtainedPart.DescriptionEnglish
                    ? obtainedPart.DescriptionEnglish
                    : '';
                if(!obtainedPart) return;
                fillPartInfo(part, obtainedPart);
                !part.DescriptionEnglish && obtainedPart ? part.DescriptionEnglish = obtainedPart.DescriptionEnglish : null;
            });
            $scope.loading = false;
        })
    }
    $scope.les = (part) => {
        // console.log(part)
    };
    $controller('SimpleModalCtrl', {
        $scope: $scope,
        $uibModalInstance: $uibModalInstance,
        config: config
    })
}
