angular.module('news').directive('newsDirective', newsDirective);

newsDirective.$inject = ['ClientsServer', 'Loading', 'Message', 'MngNewsModal'];

function newsDirective(ClientsServer, Loading, Message, MngNewsModal) {
	return {
		restrict: 'EA',
		replace: true,
		templateUrl: '/app/modules/cruiser/templates/news/list.html',
		scope:{

		},
		link: function ($scope, elem, attrs, ctrl) {

			$scope.totalItems   = 0;
			$scope.itemsPerPage = 5;
			$scope.currentPage  = 1;

			$scope.searchString = '';
			$scope.searchStringUse = '';

			$scope.clients = [];

			function prependClient(user, isJustCreated){
				if(!angular.isArray($scope.clients)){
					return;
				}

				if(isJustCreated){
					user.isJustCreated = true;
				}

				$scope.clients.unshift(user);
			}

			function getUsersPage(){
				Loading.show(
					'Loading clients',
					'Please wait'
				);

				ClientsServer.newsGet({search: $scope.searchStringUse, page: $scope.currentPage}, function(paginator){
					$scope.clients = paginator.data;

					$scope.totalItems	= paginator.total;
					$scope.itemsPerPage	= paginator.per_page;
					$scope.currentPage	= paginator.current_page;
				});
			}

			$scope.clearSearch = function(){

				$scope.searchString = '';
				$scope.searchStringUse = '';

				getUsersPage();
			};

			$scope.search = function(){

				$scope.searchStringUse = $scope.searchString;

				getUsersPage();
			};

			$scope.addClient = function(userData){


				MngNewsModal.open(userData).then(function(result){
					if(result.button != 'save'){return;}

					Loading.show(
						'Saving user',
						'Please wait'
					);

					ClientsServer.newsCreate(result.data.client, (user) => getUsersPage());
				});
			};

			$scope.pageChanged = function(){
				getUsersPage();
			};

			$scope.$on('clients:list', function(){
				getUsersPage();
			});

			getUsersPage();
		}
	}
}

