
angular.module('news')
	.directive("newsListDirective", newsListDirective);

newsListDirective.$inject = ['ClientsServer', 'Loading', 'Message', 'uiSimpleModal', 'MngNewsModal', '$rootScope', '$http','ServerInterceptor'];

function newsListDirective(ClientsServer, Loading, Message, uiSimpleModal, MngNewsModal, $rootScope, $http, ServerInterceptor) {
	return {
		restrict: 'EA',
		replace: true,
		templateUrl: '/app/modules/cruiser/templates/news/listItem.html',
		scope:{
			client: '='
		},
		link: function ($scope, elem, attrs, ctrl) {

			$scope.changeCredit = function changeCredit($event){
				uiSimpleModal({
					title: 'Are you sure?',
					text: 'Credit for this client will be ' + ($scope.client.has_credit ? 'disabled' : 'enabled'),
					titleIcon: 'svg-icon icon-warning',
					size: 'sm',
					showTitleClose: false,
					buttons: ['yes', 'no']
				}).then(function(result){
					if(result === 'yes'){
						Loading.show(
							'Editing news',
							'Please wait'
						);
						ClientsServer.toggleCredit({id: $scope.client.id}, function(client){
							$scope.client = client;
						});
					}
				});

				$event.stopPropagation();
			};

			$scope.edit = function edit(userData){

				if(!userData){
					userData = $scope.client;
				}

				MngNewsModal.open(userData).then(function(result){
					if(result.button != 'save'){return;}

					Loading.show(
						'Saving changes',
						'Please wait'
					);
					console.log(ClientsServer(1,1,1));
					$http({
						'url' :`/api/news/${userData.id}`,
						'method' : 'put',
						data:{
							text: userData.text,
							title: userData.title
						},
						interceptor: ServerInterceptor
					}).then(function successCallback(response) {
						// debugger;
						Loading.hide();
						Message.pushSuccess('News saved!', 2000);
						$rootScope.$broadcast('clients:list');
						// this callback will be called asynchronously
						// when the response is available
					}, function errorCallback(response) {
						// debugger
						console.log(response)
						// called asynchronously if an error occurs
						// or server returns response with an error status.
					});
				});
			};
			//
			$scope.delete = function(client){

				uiSimpleModal({
					title: 'Are you sure?',
					text: 'Client will be deleted!',
					titleIcon: 'svg-icon icon-warning',
					size: 'sm',
					showTitleClose: false,
					buttons: ['yes', 'no']
				}).then(function(result){
					if(result === 'yes'){
						Loading.show(
							'Deleting News',
							'Please wait'
						);

						$http({
							'url' :`/api/news/${client.id}`,
							'method' : 'delete',
							interceptor: ServerInterceptor
						}).then(function successCallback(response) {
							// debugger;
							Loading.hide();
							Message.pushSuccess('News delete!', 2000);
							$rootScope.$broadcast('clients:list');
							// this callback will be called asynchronously
							// when the response is available
						}, function errorCallback(response) {
							// debugger
							console.log(response)
							// called asynchronously if an error occurs
							// or server returns response with an error status.
						});

						// ClientsServer.delete({id: client.id}, function(){
						// 	Message.pushSuccess('Client was successfully deleted!', 2000);
						// 	$rootScope.$broadcast('clients:list');
						// });
					}
				});
			}
		}
	}
}

