angular.module('cruiser').controller('deleteservCtrl', deleteservCtrl)

deleteservCtrl.$inject = [
    '$scope',
    '$http',
    '$uibModalInstance',
    'Message'
]

function deleteservCtrl($scope, $http, $uibModalInstance, Message) {
    console.log($scope)
    $scope.delete_items = function(){
        if($scope.data.password != $scope.data.confirm_password){
            Message.pushError('Enter the correct password', 2000);
            return;
        }
        $uibModalInstance.close($scope.data.password);
    }
}