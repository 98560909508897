

angular.module('manager.users')
	.service('UsersServer', UsersServer);

UsersServer.$inject = ['$resource', 'ServerInterceptor'];

function UsersServer($resource, ServerInterceptor) {

	return  $resource('/users',{	}, {
		'list': {
			'url' :'/users/list',
			'method' : 'POST',
			interceptor: ServerInterceptor
		},
		'toggleActive': {
			'url' :'/users/toggle_active',
			'method' : 'POST',
			interceptor: ServerInterceptor
		},
		'save': {
			'url' :'/users/save',
			'method' : 'POST',
			interceptor: ServerInterceptor
		},
		'create': {
			'url' :'/users/create',
			'method' : 'POST',
			interceptor: ServerInterceptor
		},
		'delete': {
			'url' :'/users/delete',
			'method' : 'POST',
			interceptor: ServerInterceptor
		},
		'setPassword': {
			'url' :'/users/set_password',
			'method' : 'POST',
			interceptor: ServerInterceptor
		}
	});
}