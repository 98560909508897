angular.module('cruiser').directive('searchResults', SearchResultsDirective);

SearchResultsDirective.$inject = [];

function SearchResultsDirective() {
	return {
		restrict:    "EA",
		replace:     true,
		transclude:  true,
		templateUrl: "/app/modules/cruiser/templates/searchResults.html",
	}
}