angular.module('cruiser').directive('editCaStock', EditCAStockDirective);

EditCAStockDirective.$inject = ['StockCa', 'Parts', '$timeout', 'uiSimpleModal', 'Message', '$controller'];

function EditCAStockDirective(StockCa, Parts, $timeout, uiSimpleModal, Message, $controller) {
	return {
		restrict:    "EA",
		scope:       {},
		templateUrl: "/app/modules/cruiser/templates/editCAStock.html",
		link:        function ($scope) {
			function setStockData(data, resetChanges = false){
				let fillableFields = [
					'id',
					'Part_id',
					'Price',
					'diff_stock_date_with_today',
					'Stock_date',
					'Stock_Qty',
					'Location',
					'Condition',
					'brand',
					'PartNumber'
				];

				$scope.stockData = $scope.stockData || {};

				$scope.parts.forEach(function(part){
					if(!part.PartNumber) {
						return;
					}

					let obtainedPart = data[part.PartNumber];

					if(!obtainedPart){
						return;
					}

					$scope.stockData[part.PartNumber] = $scope.stockData[part.PartNumber] || {};

					if(!part.DescriptionEnglish && obtainedPart){
						part.DescriptionEnglish = obtainedPart.DescriptionEnglish;
					}

					fillableFields.forEach(function(field){
						part[field] = obtainedPart[field];
						$scope.stockData[part.PartNumber][field] = obtainedPart[field];

						if(resetChanges) {
							$scope.stockData[part.PartNumber].increase = null;
							$scope.stockData[part.PartNumber].decrease = null;
						}
					})
				});

			}

			$scope.overrideSearch = function(numbers, resetChanges = false){
				$scope.loading = true;

				StockCa.search({filters: $scope.filters, parts: $scope.parts, page: $scope.currentPage}, function(data){
					$scope.loading = false;

					setStockData(data, resetChanges);
				})
			};

			$scope.changeQty = function () {

				let promise = uiSimpleModal({
					title:        'Confirm edit stock',
					windowClass:  'edit-stock-modal',
					templateUrl:  '/app/modules/cruiser/templates/editStockModal.html',
					buttons: 	  ['save', 'cancel'],
					returnData:   true,
					wrapTemplate: false,
					data:         {}
				});

				promise.then(function(result){
					if(result.button != 'save'){
						return;
					}

					StockCa.saveStock({
						password: result.data.password,
						stock: $scope.stockData
					}, function(response){

						if(!response){
							return;
						}

						Message.pushSuccess('Stock updated!', 2000);
						$scope.overrideSearch(null, true);
					})
				});
			};

			$scope.createStock = function(){
				let promise = uiSimpleModal({
					title:        'Add part to Canada stock',
					windowClass:  'edit-stock-modal',
					templateUrl:  '/app/modules/cruiser/templates/createStockModal.html',
					buttons: 	  ['save', 'cancel'],
					returnData:   true,
					wrapTemplate: false,
					data:         {
						stock: {},
						brands: $scope.brands
					}
				});

				promise.then(function(result){
					if(result.button != 'save'){
						return;
					}

					StockCa.create({
						password: result.data.password,
						stock: result.data.stock
					}, function(response){

						if(!response){
							return;
						}

						Message.pushSuccess(`Part ${result.data.stock.PartNumber} added to stock!`, 2000);
						$scope.overrideSearch(null, true);
					})
				});
			};

			$scope.stockClass = function(stock){
				if(!$scope.stockData){
					return;
				}

				let part = $scope.stockData[stock.PartNumber];

				if(!part){
					return;
				}

				if(part.decrease){
					return 'table-danger';
				}

				if(part.increase){
					return 'table-success';
				}
			};

			$controller('SearchCtrl', {$scope: $scope});

			$scope.resetFilters();
			$scope.resetParts(1);

			$scope.showSearchInput = true;

			Parts.brands({}, function(data){
				$scope.brands = data.brands;
			});

		}
	}
}