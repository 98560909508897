
angular.module('ui.loading')
	.directive("loading", LoadingDirective);

LoadingDirective.$inject = ['$timeout'];

/**
 * 	Directive displays loading block
 * 	To display block use
 * 		$rootScope.$broadcast("showLoading", {
 * 			title: 'Loading',
 * 			text:  'Please wait.'
 * 		});
 * 	To hide block use
 * 		$rootScope.$broadcast("hideLoading");
 *
 */
function LoadingDirective($timeout) {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/app/modules/loading/templates/loading.html',
		scope:{
			loadingId: 	"@",
			title: 		"@",
			text: 		"@"
		},
		link: function (scope, elem, attrs, model) {
			scope.loadingActive = false;
			if(angular.isUndefined(scope.loadingId)){scope.loadingId = '';}

			scope.defaultTitle = angular.isDefined(scope.title) ? scope.title : 'Loading...';
			scope.defaultText  = angular.isDefined(scope.text)  ? scope.text  : 'Подождите идет загрузка...';

			scope.$on("showLoading" + scope.loadingId, function(event, data){
				
				scope.title = angular.isUndefined(data.title) ? scope.defaultTitle : data.title;
				scope.text  = angular.isUndefined(data.text)  ? scope.defaultText  : data.text;
				scope.loadingActive = true;

			});
			scope.$on("hideLoading" + scope.loadingId, function(event, forceHide){
				
				scope.loadingActive = false;
				

			});
		}
	}
}