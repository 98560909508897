angular.module('cruiser').factory('backOrderService', BackOrderService)

BackOrderService.$inject = ['$http']

function BackOrderService($http) {
  const responseHandler = response => response.data
  const responseHandlerErr = err => console.log(err)

  return {
    getBrands: () => $http.get('/parts/brands').then(responseHandler, responseHandlerErr),
    findClients: params => $http.post('/clients/list', params).then(responseHandler, responseHandlerErr),
    findPartNumbers: params => $http.post('/parts/researchWithQuote', params).then(responseHandler, responseHandlerErr),
    findQuoteByClientId: clientId => $http.post('/quote/by_client', {client_id: clientId}).then(responseHandler, responseHandlerErr),
    saveBackOrder: params => $http.post('/back_order/save', {order: params}).then(responseHandler, responseHandlerErr),
    all: () => $http.get('/back_order/all').then(responseHandler, responseHandlerErr),
    deleteOrder: orderId => $http.delete(`/back_order/delete/${orderId}`).then(responseHandler, responseHandlerErr),
    findById: id => $http.get(`/back_order/find/${id}`).then(responseHandler, responseHandlerErr),
    findByDetailId: detailId => $http.post('/orderStatus/findByDetailId', {id: detailId}).then(responseHandler, responseHandlerErr),
  }
}
