angular.module('cruiser').directive('stockManagement', StockManagementDirective)

StockManagementDirective.$inject = ['$controller', '$window', '$document', "_"];

function StockManagementDirective($controller, $window, $document, _) {
    return {
        restrict: 'EA',
        scope: {
            type: '@'
        },
        templateUrl: '/app/modules/cruiser/templates/stockManagement.html',
        link: function ($scope) {
            $controller('sManagement', {$scope: $scope});

            $scope.tableHead = [
                {title: 'Brand', width: 'auto',name:'BrandName', BrandName:1},
                {title: 'PartNumber', width: 'auto', name:'PartNumber',PartNumber:1},
                {title: 'Description', width: 'auto'},
                {title: 'Stores', width: '112px'},
            ]

            $scope.tableDescriptionHead = [
                {title: 'Qty', width: 60, name:'Stock_Qty',Stock_Qty:1},
                {title: 'Min Stock', width: 60,name:'stock_min', stock_min:1},
                {title: 'List price', width: 60,name:'list_price', list_price:1},
                {title: 'Min price', width: 60,name:'min_price', min_price:1},
                {title: 'Max price', width: 60,name:'max_price', max_price:1},
                {title: 'Last Modified', width: 100,name:'updated_at', updated_at:1},
                {title: 'Location', width: 100, name:'Location', Location:1},
                {title: 'Categories', width: 100, name:'categories', categories:1},
                {title: 'Tags', width: 100},
            ];
            const calculateResize = () => {
                const headerHeight = document.getElementById('header').clientHeight // 51px
                const bodyHeight = document.body.clientHeight // 812px
                // 618px
                const newHeight = bodyHeight - headerHeight - 134
                if (newHeight !== $scope.tableHeight) {
                    const tableList = document.querySelectorAll('.tableContainer')
                    tableList.forEach(table => {
                        table.setAttribute('style', 'height:' + newHeight + 'px')
                    })

                    $scope.tableHeight = newHeight
                }

            }

            const scrollArea = $document[0].querySelector('.scrollable-area')
            const firstTable = $document[0].querySelector('.tableContainer')
            const jScrollArea = angular.element(scrollArea)

            scrollArea.onscroll = () => {
                const scrollTop = scrollArea.scrollTop;
                firstTable.scrollTo(0, scrollTop)
            }

            angular.element($window).bind('resize', calculateResize)
            angular.element('body').css({
                overflowX: 'hidden',
                overflowY: 'hidden'
            })

            jScrollArea.mousemove(_.throttle(e => {
                const widthArea = 100;
                const scrollX = scrollArea.scrollLeft

                const posX = e.target !== scrollArea
                    ? e.target.offsetLeft + e.offsetX
                    : e.offsetX

                if (posX - scrollX <= widthArea) {
                    jScrollArea.stop()
                    jScrollArea.animate({scrollLeft: scrollX - 200}, 1000)
                }
                if (posX >= scrollArea.clientWidth - widthArea + scrollX) {
                    jScrollArea.stop()
                    jScrollArea.animate({scrollLeft: scrollX + 200}, 1000)
                }
            }, 1000))

            calculateResize()
        }
    }
}
