angular.module('cruiser').service('createSingle', createSingle);

createSingle.$inject = ['uiSimpleModal']

function createSingle(uiSimpleModal) {

    return {
        open: item => {
            let title = 'Create Single'
            let windowClass = 'wideModal bundleModal';

            const dataItem = {
                brandName: item && item.brand.BrandName,
                partId: item && item.Part_id,
                brand: item && item.brand,
                partNumber: item && item.PartNumber,
                description: item && item.DescriptionEnglish,
                currentCount: item && item.Stock_Qty,
                listPrice: item && item.Price,
                location: item && item.Location || '',
                minStock: 0,
                descriptionFull: '',
                minPrice: 0,
                maxPrice: 0,
                categories: '',
                tags: [],
            }

            if (item && item.stats) {
                dataItem.minStock = item.stats.stock_min
                dataItem.minPrice = item.stats.min_price
                dataItem.maxPrice = item.stats.max_price
                dataItem.categories = item.stats.categories
                dataItem.tags = item.stats.tags
            }

            return uiSimpleModal({
                title: title,
                // size: 'lg',
                windowClass: windowClass,
                templateUrl: '/app/modules/cruiser/modals/createSingleItem/single.html',
                controller: 'CreateSingleCtrl',
                returnData: true,
                wrapTemplate: false,
                data: {
                    ...dataItem
                }
            })
        }
    }
}