angular.module('cruiser').controller('backOModalCtrl', BackOModalCtrl)

BackOModalCtrl.$inject = [
  '$scope',
  '$uibModalInstance',
  '$controller',
  'config',
  'backOrderService'
]

function BackOModalCtrl($scope, $uibModalInstance, $controller, config, backOrderService) {

  backOrderService.getBrands().then(data => $scope.brands = data.brands)

  $scope.setBrand = item => {
    $scope.data.order.brand = item.id
  }

  $scope.setClient = $item => {
    setClient($item)

    $scope.data.order.car = $item.cars[0]
    $scope.data.order.car_id = $item.cars[0] ? $item.cars[0].id : null
  }

  $scope.setPartNumber = item => {
    $scope.data.order.part_number.PartNumber = item.PartNumber
    $scope.data.order.part_number.DescriptionEnglish = item.DescriptionEnglish
    $scope.data.order.part_number.part_id = item.id
    $scope.data.order.brandName = item.part.brand.BrandName
    $scope.data.order.price = item.price_provider_raw || item.price
  }

  $scope.clientAutocomplete = val => {
    return backOrderService.findClients({search: val})
      .then(data => data.data)
  }

  $scope.partNumberAutocomplete = val => {
    const quote_id = $scope.data.order.is_stock
      ? null
      : $scope.data.order.quote.id

    return backOrderService.findPartNumbers({
      search: val,
      quote_id: quote_id,
    })
      .then(data => data.data)
  }

  function setClient(client) {
    $scope.data.order.client = client
    $scope.data.order.clientName = client.name
    $scope.data.order.client_id = client.id
    $scope.data.order.email = client.email
    $scope.data.order.quote = {}
    $scope.data.order.part_number = null
    $scope.data.order.quotes = []

    backOrderService.findQuoteByClientId(client.id).then(data => {
      $scope.data.order.quotes = data
    })

    if (client.country && client.country.code === 'CA') {
      $scope.data.order.currency = 'CAD'
    } else {
      $scope.data.order.currency = 'USD'
    }
  }

  $controller('SimpleModalCtrl', {
    $scope: $scope,
    $uibModalInstance: $uibModalInstance,
    config: config
  })
}
