angular.module('cruiser').service('StockCa', StockCaService);

StockCaService.$inject = ['$resource', 'ServerInterceptor'];

function StockCaService($resource, ServerInterceptor) {
    return $resource('/stock_ca', {"id": "@id"}, {
        'search':{
            'url': "/stock_ca/search",
            'method': 'post',
            'interceptor': ServerInterceptor
        },
        'create':{
            'url': "/stock_ca/create",
            'method': 'post',
            'interceptor': ServerInterceptor
        },
        'saveStock':{
            'url': "/stock_ca/save",
            'method': 'post',
            'interceptor': ServerInterceptor
        }
    })
}