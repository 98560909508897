angular.module('manager.users')
	.service("MngUserModal", MngUserModal);

MngUserModal.$inject = ['uiSimpleModal', 'ClientsServer'];

function MngUserModal (uiSimpleModal, ClientsServer) {
	
	function prepareUserObject(userOriginal){
		var user = angular.copy(userOriginal);
		
		if(angular.isDefined(user.is_active)){
			// make boolean to use with checkbox
			user.is_active = !!user.is_active;
		}
		
		return user;
	}
	
	return {
		open: function(user){
			var title = 'Edit user';
			var windowClass = 'mng-user-edit-modal';
			
			if(user){
				user = prepareUserObject(user);
			}else{
				user = {};
				title = 'Save user';
				windowClass += ' create-user';
			}

			var promise = uiSimpleModal({
				title:        title,
				windowClass:  windowClass,
				templateUrl:  '/app/modules/users/templates/userModal.html',
				buttons: 	  ['save', 'cancel'],
				controller: 'userModalCtrl',
				returnData:   true,
				wrapTemplate: false,
				data:         {
					user: user
				}
			});
			
			return promise;
		},
		password: function(data){
			
			if(data){
				data = angular.copy(data);
			}else{
				data = {};
			}
			
			
			
			var promise = uiSimpleModal({
				title:        'Set password for user',
				windowClass:  'mng-user-edit-modal set-password',
				templateUrl:  '/app/modules/users/templates/userModalPassword.html',
				buttons: 	  ['save', 'cancel'],
				returnData:   true,
				wrapTemplate: false,
				data:         data
			});
			
			return promise;
		}
	};
}