
angular.module('ui.simplePagination')
	.directive("uiSimplePagination", UiSimplePagination);


UiSimplePagination.$inject = [];

function UiSimplePagination() {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/app/modules/simplePagination/templates/pagination.html',
		scope: false,	// use parents scope
		link: function (scope, elem, attrs) {
		
		
		}
	}
}
