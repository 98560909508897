

angular.module('news')
	.service('ClientsServer', ClientsServer);

ClientsServer.$inject = ['$resource', 'ServerInterceptor'];

function ClientsServer($resource, ServerInterceptor) {
	return  $resource('/clients', {'id': "@id"}, {
		'list': {
			'url' :'/clients/list',
			'method' : 'POST',
			interceptor: ServerInterceptor
		},
		'newsGet': {
			'url' :'/api/news',
			'method' : 'get',
			interceptor: ServerInterceptor
		},
		'newsCreate': {
			'url' :'/api/news',
			'method' : 'post',
			interceptor: ServerInterceptor
		},
		'newsDelete': {
			'url' :'/api/news',
			'method' : 'delete',
			interceptor: ServerInterceptor
		},
		newsEdit: {
			'url' :'/api/news',
			'method' : 'put',
			interceptor: ServerInterceptor
		},
		'get': {
			'url' :'/clients/get',
			'method' : 'POST',
			interceptor: ServerInterceptor
		},
		'save': {
			'url' :'/clients/save',
			'method' : 'POST',
			interceptor: ServerInterceptor
		},
		delete:{
			'url' :'/clients/delete',
			'method' : 'get',
			interceptor: ServerInterceptor
		},
		'toggleCredit': {
			'url' :'/clients/toggle_credit',
			'method' : 'POST',
			interceptor: ServerInterceptor
		},
		'countries':{
			'url': "/clients/countries",
			'method': 'get',
			'interceptor': ServerInterceptor,
			'isArray': true
		},
		'autocomplete':{
			'url': "/clients/autocomplete",
			'method': 'post',
			'interceptor': ServerInterceptor,
			'isArray': true
		},
	});
}
