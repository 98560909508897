angular.module('cruiser').service('StockManagementService', StockManagementService);

StockManagementService.$inject = ['$resource', 'ServerInterceptor'];

angular.module('cruiser').factory('StockCaService', StockCaService)

StockManagementService.$inject = ['$http']

function StockManagementService($http) {
    const responseHandler = response => response.data
    const responseHandlerErr = err => console.log(err)

    return {
        list: params => $http.get('/stock_ca/list', {params: params})
            .then(responseHandler, responseHandlerErr),
        search: params => $http.post('/stock_ca/search_n', params)
            .then(responseHandler, responseHandlerErr),
        save: params => {
            params.data.bundles.forEach( item => {
                item.partNumber = item.PartNumber ? item.PartNumber : item.PartNumber;
                item.PartNumber && delete item.PartNumber;
                return item;
            });
            return  $http.post('/stockManagement/save', params)
               .then((res) =>{
                   responseHandler(res);
                   window.location.reload();
               }, responseHandlerErr)
        },
        createBundle: params => $http.post('/stockManagement/createBundle', params)
            .then(responseHandler, responseHandlerErr),
        createSingle: params => $http.post('/stockManagement/createBundle', params)
            .then(responseHandler, responseHandlerErr),
        getBrands: () => $http.get('/parts/brands').then(responseHandler, responseHandlerErr),
        tags: params => $http.get('/stockManagement/tags', {params: params})
            .then(responseHandler, responseHandlerErr),
        searchItems: params => $http.post('/stockManagement/searchItems', params)
            .then(responseHandler, responseHandlerErr),
    }
}