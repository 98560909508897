var phpdebugbar;

angular.module('cruiser')
	.factory('ServerInterceptor', ServerInterceptor);

ServerInterceptor.$inject = ['Loading', 'Message', '$q', '$window', '$timeout', '$rootScope'];

function ServerInterceptor(Loading, Message, $q, $window, $timeout, $rootScope) {
	function handle_phpdebugbar_response(response) {
		if(angular.isUndefined(phpdebugbar) || angular.isUndefined(phpdebugbar.ajaxHandler)){
			return;
		}

		// We have a debugbar and an ajaxHandler
		// Dig through response to look for the
		// debugbar id.
		var headers = response && response.headers && response.headers();
		if (!headers) {
			return;
		}
		// Not very elegant, but this is how the debugbar.js defines the header.
		var headerName = phpdebugbar.ajaxHandler.headerName + '-id';
		var debugBarID = headers[headerName];
		if (debugBarID) {
			// A debugBarID was found! Now we just pass the
			// id to the debugbar to load the data
			phpdebugbar.loadDataSet(debugBarID, ('ajax'));
		}
	}

	function handleResponseError(response) {
		var error = '';

		var errorData = [];
		var errorCode;

		if(angular.isString(response.data)){
			// just string
			errorData = response.data;
		}else if(angular.isDefined(response.data) && angular.isDefined(response.data.message)){
			// error object
			// todo: convert error message to site language using error code
			errorData = response.data.message + ' on ' + response.data.file + ' line ' + response.data.line;
		}else{
			// object like:
			// {
			// 		"contacts.phone": [
			// 			"validation.phone"
			// 		],
			// 		"contacts.phone7":[
			// 			"The contacts.phone7 field is required."
			// 		]
			// } anna_cruiser_addiction
			// } yan_cruiser_addiction
			angular.forEach(response.data, function(value, key) {
				errorData = errorData.concat(value);
			});
		}

		if(response.data.errors) {
			angular.forEach(response.data.errors, function(value, key) {
				errorData = errorData.concat(value) + "\r\n";
			});
		}

		return errorData;

	}

	function sendSetFormServerValidation(data){

		$rootScope.$broadcast('set-form-server-validation--' + data.formName, data);

	}

	return {
		response: function(response){
			Loading.hide();

			handle_phpdebugbar_response(response);

			return response.data || $q.when(response);
		},
		responseError: function (response) {
			var error = handleResponseError(response);

			Loading.hide();

			if (error && response.status !== 401) {
				Message.pushError(error);
			}

			// if(response.status == 401){
			// 	$timeout(function(){
			// 		$window.location.reload();
			// 	}, 0);
			// }

			return response;
		}
	};


}
