angular.module('cruiser').service('backOModal', BackOModal)

BackOModal.$inject = ['uiSimpleModal']

function BackOModal(uiSimpleModal) {

  return {
    open: detailId => {
      let title = 'Add New Order'
      let windowClass = 'back-order-modal'

      const order = {
        id: 0,
        brand_id: null,
        car_id: null,
        clientName: '',
        client_id: 0,
        quote: {},
        part_number: {},
        back_order_status_id: 1,
        qty: 1,
        qty_received: 0,
        description: '',
        price: 0,
        is_stock: false
      }

      if (detailId) {
        const data = detailId
          if (data.backOrderId){
            order.id = data.backOrderId
          }
          if(data.back_order_status_id){
            order.back_order_status_id = +data.back_order_status_id
          }
          order.car_id = data.car_id
          order.clientName = data.customer_name
          order.client_id = data.client_id
          order.description = data.description
          order.price = data.price
          order.email = data.email
          order.brandName = data.brand_name
          order.quote = data.quote
          order.quotes = [data.quote]
          order.qty = +data.qty
          order.part_number = {
            DescriptionEnglish: data.description,
            PartNumber: data.part_number,
            part_id: data.part_id,
          }
          order.car = {
            model: data.car_model,
          }

          order.is_stock = !order.client_id
      }

      return uiSimpleModal({
        title: title,
        size: 'lg',
        windowClass: windowClass,
        templateUrl: '/app/modules/cruiser/modals/backOModal.html',
        buttons: ['save', 'cancel'],
        returnData: true,
        wrapTemplate: false,
        controller: 'backOModalCtrl',
        data: {
          dt: new Date(),
          format: 'dd/MM/yyyy',
          popup1: {
            opened: false
          },
          open1: (data) => {
            data.popup1.opened = true
          },
          dateOptions: {
            dateDisabled: false,
            formatYear: 'yy',
            maxDate: new Date(2020, 5, 22),
            minDate: new Date(),
            startingDay: 1,
            showWeeks: false
          },
          altInputFormats: ['M!/d!/yyyy'],
          detailId: detailId,
          order
        }
      })
    }
  }
}
