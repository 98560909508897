angular.module('cruiser').service('Quotes', QuotesService)

QuotesService.$inject = ['$resource', 'ServerInterceptor']

function QuotesService($resource, ServerInterceptor) {
  return $resource('/quote', {'id': '@id'}, {
    'save': {
      'url': '/quote/save',
      'method': 'post',
      'interceptor': ServerInterceptor
    },
    'get': {
      'url': '/quote/:id',
      'method': 'get',
      'interceptor': ServerInterceptor
    },
    'list': {
      'url': '/quote/list',
      'method': 'get',
      'interceptor': ServerInterceptor,
      'isArray': true
    },
    'taxes': {
      'url': '/quote/taxes',
      'method': 'get',
      'interceptor': ServerInterceptor,
    },
    'invoice': {
      'url': '/quote/invoice',
      'method': 'post',
      'interceptor': ServerInterceptor
    },
    'rate': {
      'url': '/quote/rate',
      'method': 'get',
      'interceptor': ServerInterceptor
    },
  })
}
