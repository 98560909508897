angular.module('cruiser').service('OrdersS', OrdersService)

OrdersService.$inject = ['$resource', 'ServerInterceptor']

function OrdersService($resource, ServerInterceptor) {
  return $resource('/orderStatus', {'id': '@id'}, {
    'all':{
      'url': '/parts/orders',
      'method': 'get',
      'interceptor': ServerInterceptor,
    },
  })
}
