
angular.module('manager.users')
	.directive("mngUserListItem", MngUserListItem);

MngUserListItem.$inject = ['UsersServer', 'Loading', 'Message', 'uiSimpleModal', 'MngUserModal', '$rootScope'];

function MngUserListItem(UsersServer, Loading, Message, uiSimpleModal, MngUserModal, $rootScope) {
	return {
		restrict: 'EA',
		replace: true,
		templateUrl: '/app/modules/users/templates/listItem.html',
		scope:{
			user: '='
		},
		link: function ($scope, elem, attrs, ctrl) {
			
			$scope.changeActive = function changeActive($event){
				uiSimpleModal({
					title: 'Are you sure?',
					text: 'User will be ' + ($scope.user.is_active ? 'disabled' : 'enabled'),
					titleIcon: 'svg-icon icon-warning',
					size: 'sm',
					showTitleClose: false,
					buttons: ['yes', 'no']
				}).then(function(result){
					if(result === 'yes'){
						Loading.show(
							'Editing user',
							'Please wait'
						);
						UsersServer.toggleActive({id: $scope.user.id}, function(user){
							$scope.user = user;
						});
					}
				});
				
				
				
				$event.stopPropagation();
			};
			
			$scope.edit = function edit(userData){
				if(!userData){
					userData = $scope.user;
				}
				
				MngUserModal.open(userData).then(function(result){
					if(result.button != 'save'){return;}
					
					
					Loading.show(
						'Saving changes',
						'Please wait'
					);
					
					UsersServer.save(result.data.user, function(user){
						$scope.user = user;

						Message.pushSuccess('User was successfully updated!', 2000);
					}, function(response){
						$scope.edit(result.data.user);
					});
				});
			};
			
			$scope.setPassword = function setPassword(){
				
				MngUserModal.password().then(function(result){
					if(result.button != 'save'){return;}
					
					Loading.show(
						'Saving changes',
						'Please wait'
					);

					UsersServer.setPassword({
						'id': $scope.user.id,
						'password': result.data.password,
						'password_confirmation': result.data.password_confirmation
					}, function(user){
						$scope.user = user;
						Message.pushSuccess('Password for user was successfully updated!', 2000);
					});
				});
			};
			
			$scope.delete = function(){
				uiSimpleModal({
					title: 'Are you sure?',
					text: 'User will be deleted!',
					titleIcon: 'svg-icon icon-warning',
					size: 'sm',
					showTitleClose: false,
					buttons: ['yes', 'no']
				}).then(function(result){
					if(result === 'yes'){
						Loading.show(
							'Deleting user',
							'Please wait'
						);

						UsersServer.delete({id: $scope.user.id}, function(){
							$rootScope.$broadcast('users:list');
							Message.pushSuccess('User was successfully deleted!', 2000);

						});
					}
				});

			}
		}
	}
}

