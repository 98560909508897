
angular.module('etCopy')
	.directive("etCopy", EtCopyDirective);


EtCopyDirective.$inject = ['EtCopy'];

function EtCopyDirective(EtCopy) {
	return {
		restrict: 'A',
		scope: {
			text: '<etCopy',
		},
		link: function (scope, element, attrs) {
			
			function copyToClipboard(){
				
				EtCopy(scope.text);
			
			}
			
			element.on('click', copyToClipboard);
			
		}
	}
}

