angular.module('cruiser').service('wideModal', WideModal)

WideModal.$inject = ['uiSimpleModal']

function WideModal(uiSimpleModal) {

    return {
        open: item => {
            let title = 'CA Warehouse';
            let windowClass = 'wideModal';
            const dataItem = {
                brandName: item.brand.BrandName,
                partId: item.Part_id,
                brand: item.brand,
                part: item.part,
                stock_Qty: item.StockQty,
                bundles: item.stats? item.stats.bundles : [],
                partNumber: item.PartNumber,
                description: item.DescriptionEnglish,
                currentCount: item.Stock_Qty,
                listPrice: item.Price,
                location: item.Location || '',
                descriptionFull: item.description_full,
                minStock: 0,
                minPrice: 0,
                maxPrice: 0,
                categories: item && item.stats && item.stats.categories && item.stats.categories.categories
                    ? item.stats.categories.categories
                    : '' ,
                tags: []
            };
            if(item.stats){
                dataItem.bundles = dataItem.bundles.map(res => {
                    res.pivot && delete res.pivot;
                    res.DescriptionEnglish =res.description;
                    res.PartNumber = res.partNumber;
                    return res
                });
                dataItem.minStock = item.stats.stock_min;
                dataItem.minPrice = item.stats.min_price;
                dataItem.maxPrice = item.stats.max_price;
                dataItem.categories = item && item.stats && item.stats.categories && item.stats.categories.categories
                    ? item.stats.categories.categories
                    : '';
                dataItem.tags = item.stats.tags
            } else {
                item.stats = {};
                item.stats.bundles = []
            }

            return uiSimpleModal({
                title: title,
                size: 'lg',
                windowClass: windowClass,
                templateUrl: '/app/modules/cruiser/modals/wideModal/wideModal.html',
                controller: 'WideModalCtrl',
                returnData: true,
                wrapTemplate: false,
                data: {
                    ...dataItem
                }
            })
        }
    }
}
