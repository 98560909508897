angular.module('ui.loading').directive('preloadSrc', PreloadSrcDirective);

PreloadSrcDirective.$inject = ['$rootScope', '$timeout'];

function PreloadSrcDirective($rootScope, $timeout) {
	return {
		link: function(scope, element, attrs) {

			function loadImage() {
				$rootScope.$broadcast('blProduct:imageLoading');
				preloader.addClass('active');

				element[0].onload = function() {
					preloader.removeClass('active');
					$rootScope.$broadcast('blProduct:imageLoaded');
				};

				element[0].src = attrs.preloadSrc;
			}

			scope.$watch((function() {
				return attrs.preloadSrc;
			}), function(newVal, oldVal) {
				if (oldVal !== newVal && newVal) {
					loadImage();
				}
			});

			var preloader = angular.element('<div class="preloader" id="loader-' + scope.$id +'"></div>');
			element.after(preloader);

			$timeout(function(){
				loadImage();
			}, 50)
		}
	}
}