angular.module('cruiser').controller('sManagement', SManagementController)

SManagementController.$inject = [
    '$scope',
    'NgTableParams',
    'wideModal',
    'createBundle',
    'createSingle',
    'deleteItem',
    'StockManagementService',
    'Message',
    '$http'
]

function SManagementController($scope, NgTableParams, wideModal, createBundle, createSingle,deleteItem, StockManagementService, Message, $http) {
    $scope.items = []
    $scope.searchValue = ''

    $scope.totalItems = 64
    $scope.currentPage = 1
    $scope.maxSize = 5
    $scope.itemsPerPage = 50
    $scope.searchKeyValue = {
        "1":'',
        "2":'asc',
        "3":'desc',
    }
    const mapper = item => {
        if (item.stats) {
            item.stats.updated_at = new Date(item.stats.updated_at)
        }
        return item
    }

    const setItems = result => {
        $scope.items = result.data.map(mapper)
        $scope.currentPage = result.current_page
        $scope.totalItems = result.total
    }

    $scope.searchItems = () => {
        const value = $scope.searchValue.toLowerCase();

        if (value.length > 2) {
            StockManagementService.search({value, page: $scope.currentPage}).then(result => setItems(result))
        }

        if (value.length === 0) {
            StockManagementService.list({page: $scope.currentPage}).then(result => setItems(result))
        }
    };

    $scope.searchItems();

    $scope.newSearch = function (name){
        $scope.tableDescriptionHead = $scope.tableDescriptionHead.map(item => {
            item.name != name ? item[item.name] = 1 : null;
            if(item.name == name) {
                item[name] >= 1 && item[name] < 3 ? item[name] = item[name] + 1 : item[name] =  1
            }
            return item
        });

        $scope.tableHead= $scope.tableHead.map(item => {
            item.name != name? item[item.name] = 1 : null;
            if(item.name == name) {
                item[name] >= 1 && item[name] < 3 ? item[name] = item[name] + 1 : item[name] =  1
            }
            return item
        });
        let active = {
            name:'',
            value:''
        };
        $scope.tableDescriptionHead.forEach(item => {
            if(item.name && item[item.name] > 1) {
                active.name = item.name;
                active.value = $scope.searchKeyValue[item[item.name]];
            }
        });
        $scope.tableHead.forEach(item => {
            if(item.name && item[item.name] > 1) {
                active.name = item.name;
                active.value = $scope.searchKeyValue[item[item.name]];
            }
        });
        if((active.name && active.value) && active.value != 1){
            StockManagementService.list({
                page: $scope.currentPage,
                order: active.value,
                sort: active.name
            }).then(result => setItems(result))
        } else {
            StockManagementService.list({page: $scope.currentPage}).then(result => setItems(result))
        }

    };
    $scope.checkboxes = {
        checkedAll: false,
        items: [],
    }

    $scope.checkAll = () => {
        const checked = $scope.checkboxes.checkedAll

        $scope.checkboxes.checkedAll = !checked
        $scope.items.forEach(item => $scope.checkboxes.items[item.Part_id] = !checked)
    }

    $scope.toggleCheck = id => {
        $scope.checkboxes.items[id] = !$scope.checkboxes.items[id]
    }

    $scope.getCheckedClass = id => {
        const checked = $scope.checkboxes.items[id]
        const baseClass = 'fas fa-camera image-check'

        return checked ? baseClass + ' checked' : baseClass
    }

    $scope.createBundle = () => {
        createBundle.open()
            .then(
                result => {
                    StockManagementService.createBundle(result)
                        .then(response => {
                            if (response && response.Id > 0) {
                                Message.pushSuccess('The bundle was saved successfully', 2000)
                                $scope.searchItems && $scope.searchItems()
                                $scope.searchItems()
                            }
                        })
                },
                () => console.warn('Canceled')
            )
    }

    $scope.createSingle = () => {
        console.log('createSingle')
        createSingle.open()
            .then(
                result => {
                    StockManagementService.createSingle(result)
                        .then(response => {
                            if (response && response.Id > 0) {
                                Message.pushSuccess('The bundle was saved successfully', 2000)
                                $scope.searchItems && $scope.searchItems();
                                $scope.searchItems()
                            }
                        })
                },
                () => console.warn('Canceled')
            )
    }

    $scope.editGood = item => {
        wideModal.open(item)
            .then(result => {
                StockManagementService.save(result)
                    .then(response => {
                        if (response && response.Id > 0) {
                            Message.pushSuccess('The good was saved successfully', 2000)
                            $scope.searchItems && $scope.searchItems()
                            $scope.searchItems()
                        }
                    })
            }, () => console.warn('Canceled'))
    }

    $scope.deleteItems = item => {
        let arr = [];
        Object.keys($scope.checkboxes.items)
           .forEach(item => $scope.checkboxes.items[item] && arr.push(item));
        deleteItem.open(arr)
            .then(result => {
                $http({
                    'url' :`/api/stockManagement/`,
                    'method' : 'delete',
                    data:{
                        items: arr,
                        password: result
                    },
                }).then(function successCallback(response) {
                    Message.pushSuccess('The good was deleted items successfully', 2000)
                    window.location.reload()
                    // console.log(response)

                }, function errorCallback(response) {
                    Message.pushError('The bad was deleted items successfully', 2000)
                });
            }, (err) => {
                console.log(err)
            })
    }

    $scope.getAllTags = tagArr => {
        if (Array.isArray(tagArr) && tagArr.length > 0) {
            let descr = tagArr.map(tag => tag.description);
            return `${tagArr.map(tag => tag.description)[0]} +${descr.length - 1}`
        }
        return '';
    }

    $scope.tableHeight = 0;
}
