angular.module('cruiser').directive('orderStatus', OrderStatusDirective)

OrderStatusDirective.$inject = ['$controller', 'OrdersS', 'Parts']

/** @ngInject */
function OrderStatusDirective($controller, OrdersS, Parts) {
  return {
    restrict: 'EA',
    scope: {
      type: '@'
    },
    templateUrl: '/app/modules/cruiser/templates/orderStatus.html',
    link: function ($scope) {
      const Statuses = {
        1: {
          title: 'In process',
          class: 'blue-color',
        },
        2: {
          title: 'Purchased',
          class: 'green-color',
        },
        3: {
          title: 'Damaged',
          class: 'red-color',
        },
        4: {
          title: 'Shipped',
          class: 'green-color',
        },
        5: {
          title: 'Discontinued',
          class: 'purple-color'
        },
        6: {
          title: 'Out of stock',
          class: 'red-color',
        },
        7: {
          title: 'Discontinued',
          class: '',
        },
        8: {
          title: 'Low order price',
          class: '',
        },
        9: {
          title: 'Out of stock',
          class: 'red-color',
        },
        10: {
          title: 'Out of stock',
          class: 'red-color',
        }
      }

      $scope.orders = []

      Parts.orders({}, function (data) {
        const {orders, totalAW, totalVW, totalMoney} = data

        $scope.orders = Object.values(orders).sort((a, b) => b.id - a.id)

        $scope.totalVW = totalVW
        $scope.totalAW = totalAW
        $scope.totalDiff = Math.abs(totalVW / totalAW)
        $scope.totalMoney = totalMoney

        const totalInfo = {
          goal: 45 - totalAW,
          className: 'alert-danger'
        }

        if (totalAW > 45) {
          totalInfo.goal = 100 - totalAW
          totalInfo.className = 'alert-info'
        }

        if (totalAW > 100) {
          totalInfo.goal = 0
          totalInfo.className = 'alert-success'
        }

        $scope.totalInfo = totalInfo
      })

      $scope.range = function (n, startN = 1) {
        const arr = []
        for (let i = startN; i <= n; i++) {
          arr.push(i)
        }
        return arr
      }

      $scope.getStatus = function (status) {
        return Statuses[+status] !== undefined
          ? Statuses[+status].title
          : ''
      }

      $scope.getStatusColor = function (status) {
        return Statuses[+status] !== undefined
          ? Statuses[+status].class
          : ''
      }

      $scope.getCircleColor = function (status) {
        return Statuses[+status] !== undefined
          ? `colored-circle ${Statuses[+status].class}`
          : ''
      }

      $scope.child = function (n) {
        return `child-${n}`
      }

      $scope.dottedResult = function (value, postChar = '', preChar = '') {
        return (!!value && (+value !== 0)) ? `${preChar}${(+value).toFixed(2)}${postChar}` : '-'
      }

      $controller('orderStatus', {$scope: $scope})
    }
  }
}
