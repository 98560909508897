
angular.module('manager.users')
	.directive("mngUserList", MngUserList);

MngUserList.$inject = ['UsersServer', 'Loading', 'Message', 'MngUserModal'];

function MngUserList(UsersServer, Loading, Message, MngUserModal) {
	return {
		restrict: 'EA',
		replace: true,
		templateUrl: '/app/modules/users/templates/list.html',
		scope:{

		},
		link: function ($scope, elem, attrs, ctrl) {

			$scope.totalItems   = 0;
			$scope.itemsPerPage = 5;
			$scope.currentPage  = 1;

			$scope.searchString = '';
			$scope.searchStringUse = '';
			
			$scope.users = [];

			function prependUser(user, isJustCreated){
				if(!angular.isArray($scope.users)){
					return;
				}
				
				if(isJustCreated){
					user.isJustCreated = true;
				}
				
				$scope.users.unshift(user);
			}

			function getUsersPage(){
				Loading.show(
					'Loading users',
					'Please wait'
				);

				UsersServer.list({search: $scope.searchStringUse, page: $scope.currentPage}, function(paginator){
					$scope.users = paginator.data;

					$scope.totalItems	= paginator.total;
					$scope.itemsPerPage	= paginator.per_page;
					$scope.currentPage	= paginator.current_page;
				});
			}

			$scope.clearSearch = function(){
				
				$scope.searchString = '';
				$scope.searchStringUse = '';
				
				getUsersPage();
			};
			
			$scope.search = function(){
				
				$scope.searchStringUse = $scope.searchString;
				
				getUsersPage();
			};
			
			$scope.addUser = function(userData){
				MngUserModal.open(userData).then(function(result){
					if(result.button != 'save'){return;}
					
					Loading.show(
						'Saving user',
						'Please wait'
					);
					
					UsersServer.create(result.data.user, function(user){
						prependUser(user, true);
					}, function(response){
						$scope.addUser(result.data.user);
					});
				});
			};

			$scope.pageChanged = function(){
				getUsersPage();
			};

			$scope.$on('users:list', function(){
				getUsersPage();
			});

			getUsersPage();
		}
	}
}

