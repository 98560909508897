angular.module('cruiser', [
    'ngAnimate',
    'ngStorage',
    'ngResource',
    'ngSanitize',
    'templates',
    'ui.bootstrap',
    'ngTable',
    'ui.sortable',
    'jsonFormatter',
    'ui.loading',
    'ui.message',
    'ui.simpleModal',
    'etCopy',
    'ngMask',
    'manager.users',
    'manager.clients',
    'news',
    'ui.simplePagination',
    'ui.validationErrorMessage',
])
    .constant('_', window._);
