angular.module('cruiser').directive('search', SearchDirective);

SearchDirective.$inject = ['Parts', 'Quotes', '$timeout', '$localStorage', '$controller'];

function SearchDirective(Parts, Quotes, $timeout, $localStorage, $controller) {
	return {
		restrict:    "EA",
		scope:       {},
		templateUrl: "/app/modules/cruiser/templates/search.html",
		link:        function ($scope) {
			$controller('SearchCtrl', {$scope: $scope});
			$controller('QuoteCtrl',  {$scope: $scope});

			$scope.emptyInit = true;

			Parts.warehouses({}, function(data){
				$scope.warehouses = data.warehouses;
			});

			Parts.brands({}, function(data){
				$scope.brands = data.brands;
			});

			$scope.resetFilters();
			$scope.resetParts();
		}
	}
}