
angular.module('etCopy')
	.service("EtCopy", EtCopy);

EtCopy.$inject = ['$window'];

function EtCopy($window) {
	let body = angular.element($window.document.body);
	let textarea = angular.element('<textarea/>');
	textarea.css({
		position: 'fixed',
		opacity: '0'
	});
	
	return function (text) {
		textarea.val(text);
		body.append(textarea);
		textarea[0].select();
		
		
		try {
			let successful = document.execCommand('copy');
			if (!successful) throw successful;
		} catch (err) {
			window.prompt("Copy to clipboard: Ctrl+C, Enter", text);
		}
		
		textarea.remove();
	}
}

