angular.module('cruiser').directive('research', ResearchDirective);

ResearchDirective.$inject = ['Parts', '$controller'];

function ResearchDirective(Parts, $controller) {
	return {
		restrict:    "EA",
		scope:       {},
		templateUrl: "/app/modules/cruiser/templates/research.html",
		link:        function ($scope) {
			$scope.resetFilters = function(){
				$scope.filters = {
					search: '',
					show_prices: true,
					page: 1,
					brands: [{
						id: 145,
						BrandName: 'TOYOTA'
					}], //toyota
					search_inside: false
				};
			};

			$scope.addBrand = function (brand) {
				$scope.filters.brands.push(brand);
				$scope.lookup = '';
			};

			$scope.removeBrand = function (index) {
				$scope.filters.brands.splice(index, 1);
			};

			$scope.pageChanged = function(reset = false){
				if(reset){
					$scope.resetFilters();
				}

				$scope.search()
			};

			$scope.search = function(){
				$scope.loading = true;

				Parts.research({filters: $scope.filters, page: $scope.currentPage}, function(paginator){
					$scope.loading = false;

					$scope.parts = paginator.data;

					$scope.totalItems	= paginator.total;
					$scope.itemsPerPage	= paginator.per_page;
					$scope.currentPage	= paginator.current_page;
				})
			};

			$scope.resetFilters();

			$controller('QuoteCtrl',  {$scope: $scope});

			$scope.showSearchInput = true;

			Parts.warehouses({}, function(data){
				$scope.warehouses = data.warehouses;
			});

			Parts.brands({}, function(data){
				$scope.brands = data.brands;
			});
		}
	}
}