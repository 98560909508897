angular.module('cruiser').directive('emptyPeriods', EmptyPeriodsDirective);

EmptyPeriodsDirective.$inject = [];

function EmptyPeriodsDirective() {
	return {
		restrict:    "EA",
		scope:       {},
		templateUrl: "/app/modules/cruiser/templates/emptyPeriods.html",
		link:        function ($scope) {
			let dataElem = $('#empty-periods-data');

			$scope.data = JSON.parse(dataElem.text());

			dataElem.remove();
			$('#graph').remove();


		}
	}
}