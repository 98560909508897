angular.module('cruiser').controller('SearchCtrl', SearchController);

SearchController.$inject = ['$scope', 'Parts', '$timeout', 'Message'];

function SearchController($scope, Parts, $timeout, Message) {
    let scrollerId = 'other-warehouses';

    function mouseWheelHandler(e) {
        e.stopPropagation();
        e.preventDefault();

        let delta = -20 * (Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail))));
        let scrollerElem = $('#' + scrollerId);

        let pst = scrollerElem.scrollLeft() + delta;

        if (pst < 0) {
            pst = 0;
        } else if (pst > $('.img_holder').width()) {
            pst = $('.img_holder').width();
        }

        scrollerElem.scrollLeft(pst);

        return false;
    }

    function attachScrollEvent() {
        let scroller = document.getElementById(scrollerId);

        if (!scroller) {
            return;
        }

        if (scroller.addEventListener) {
            scroller.addEventListener("mousewheel", mouseWheelHandler, false);
            scroller.addEventListener("DOMMouseScroll", mouseWheelHandler, false);
        } else {
            scroller.attachEvent("onmousewheel", mouseWheelHandler);
        }
    }

    async function parseInput(string, delimiter) {
        return Parts.csv({csv: string, delimiter: delimiter}).$promise.then(function (data) {
            return data;
        })
    }

    function fillPartInfo(exising, retrieved) {
        let fields = [
            'id',
            'WeightPhysical',
            'BrandId',
            'WeightVolumetric',
            'ClearPartNumber',
            'stock_data_primary',
            'stock_data_secondary',
            'stock_ca',
            'stock_usa',
            'diff_stock_date_with_today'
        ];

        fields.forEach(function (field) {
            exising[field] = retrieved ? retrieved[field] : '';
        })
    }

    function cleanse(partNumber) {
        if (!partNumber) {
            return '';
        }

        return partNumber.replace(/[-"']/g, '');
    }

    function fireSearch(numbersToSearch) {
        let uniqueNumbers = {};

        $scope.parts = $scope.parts.map(function (p) {
            p.PartNumber = cleanse(p.PartNumber);

            uniqueNumbers[p.PartNumber] = p;

            return p;
        });

        let uniquePartNumbers = Object.keys(uniqueNumbers);
        let numbersUniqueToSearch = [];

        $scope.parts.forEach(function (p) {
            if (uniquePartNumbers.indexOf(p.PartNumber == -1)) {
                numbersUniqueToSearch.push(p);
            }
        });

        $scope.parts = numbersUniqueToSearch;

        if ($scope.overrideSearch && angular.isFunction($scope.overrideSearch)) {
            return $scope.overrideSearch(numbersUniqueToSearch);
        }

        let numbersArrayToSearch = numbersUniqueToSearch.map(function (n) {
            return cleanse(n.PartNumber);
        });

        // prevent multiple searches at a time
        if ($scope.loading) {
            return;
        }

        // $scope.parts = numbersToSearch;

        $scope.loading = true;
        $scope.emptyInit = false;

        Parts.search({numbers: numbersArrayToSearch, filters: $scope.filters}, function (data) {
            $scope.parts.forEach(function (part) {
                let obtainedPart = data.parts.filter(function (p) {
                    return p && p.ClearPartNumber.toLowerCase() === part.PartNumber.toLowerCase();
                })[0];

                fillPartInfo(part, obtainedPart);

                if (!part.DescriptionEnglish && obtainedPart) {
                    part.DescriptionEnglish = obtainedPart.DescriptionEnglish;
                }
            });

            // Loading.hide();

            $scope.date = data.date;

            $scope.noresults = $scope.parts.length === 0;
            $scope.search = $scope.noresults ? $scope.search : '';

            $scope.loading = false;
        })
    }

    async function parsePastedInput(data, pasteInputType) {
        let delimiter = '';

        if (data.match(/[\t]/)) {
            delimiter = "\t";
        } else if (data.match(/"/)) {
            delimiter = ",";
        }

        let parsedParts = await parseInput(data, delimiter);

        return parsedParts.map(function (partParts) {
            switch (partParts.length) {
                // special csv format obtained from provider
                case 4: {
                    return {
                        DescriptionEnglish: partParts[0],
                        PartNumber: cleanse(partParts[2]),
                        Qty: parseInt(partParts[1])
                    };
                }

                case 3: {
                    return {
                        DescriptionEnglish: partParts[0],
                        PartNumber: cleanse(partParts[1]),
                        Qty: parseInt(partParts[2])
                    };
                }

                case 2: {
                    if (partParts[1].match(/^[0-9]{1,3}$/)) {
                        if (pasteInputType == 'desc') {
                            return {
                                DescriptionEnglish: partParts[0],
                                PartNumber: cleanse(partParts[1]),
                                Qty: 0
                            }
                        }

                        if (pasteInputType == 'part') {
                            return {
                                DescriptionEnglish: '',
                                PartNumber: cleanse(partParts[0]),
                                Qty: parseInt(partParts[1])
                            }
                        }
                    }

                    return {
                        DescriptionEnglish: partParts[0],
                        PartNumber: cleanse(partParts[1]),
                        Qty: 0
                    }
                }
                case 1: {
                    if (pasteInputType == 'desc') {
                        return {
                            DescriptionEnglish: partParts[0],
                            PartNumber: '',
                            Qty: 0
                        }
                    }

                    if (pasteInputType == 'part') {
                        return {
                            DescriptionEnglish: '',
                            PartNumber: cleanse(partParts[0]),
                            Qty: 0
                        }
                    }

                    if (pasteInputType == 'qty') {
                        return {
                            DescriptionEnglish: '',
                            PartNumber: '',
                            Qty: parseInt(partParts[0])
                        }
                    }
                }
            }
        })
    }

    function iterateOverInputs($input) {

        const parentRow = $($input).parents('.part-info');
        const childs = parentRow.find('.input-only');
        const childsCnt = childs.length;

        const currentNum = $($input).data('index');
        const nextNum = currentNum % childsCnt + 1;

        $timeout(function () {
            $(parentRow.find('.input-only[data-index="' + nextNum + '"]')).focus();
        }, 0);
    }

    function iterateOverRows($cell, backward = false) {
        const $row = $($cell).parents('.part-info');

        const cellIndex = $($cell).data('index');
        const parentRow = $($row).parents('.main-info');
        const childs = parentRow.find('.part-info');
        const childsCnt = childs.length;

        const currentNum = $($row).data('index');
        let nextNum;

        if (backward) {
            nextNum = currentNum % childsCnt - 1;
        } else {
            nextNum = currentNum % childsCnt + 1;
        }

        $timeout(function () {
            $(parentRow.find('.part-info[data-index="' + nextNum + '"] *[data-index="' + cellIndex + '"]')).focus();
        }, 0);
    }

    $scope.parsePaste = async function (data, index, type) {
        let parsedInput = await parsePastedInput(data, type);
        parsedInput = angular.isArray(parsedInput) ? parsedInput : [parsedInput];

        if ($scope.parts) {
            let partIndex = index;

            for (let parsedInputIndex = 0; parsedInputIndex < parsedInput.length; parsedInputIndex++) {
                $scope.parts[partIndex] = $scope.parts[partIndex] || parsedInput[parsedInputIndex];

                $scope.parts[partIndex].DescriptionEnglish = parsedInput[parsedInputIndex].DescriptionEnglish || $scope.parts[partIndex].DescriptionEnglish;
                $scope.parts[partIndex].PartNumber = parsedInput[parsedInputIndex].PartNumber || $scope.parts[partIndex].PartNumber;
                $scope.parts[partIndex].Qty = parsedInput[parsedInputIndex].Qty || $scope.parts[partIndex].Qty;

                partIndex++;
            }
        } else {
            $scope.parts = parsedInput;
        }

        fireSearch($scope.parts);
    };

    $scope.runSearch = function () {
        $scope.parts = $scope.parts.map(function (p) {
            p.PartNumber = cleanse(p.PartNumber);

            return p;
        });

        let numbersToSearch = $scope.parts;

        fireSearch(numbersToSearch);
    };

    $scope.processHotkey = function ($event) {
        let keyCode = $event.which;

        if ([9, 13, 46, 187].indexOf(keyCode) > -1) {
            $event.preventDefault();
        }

        // tab
        if (keyCode == 9) {
            return iterateOverInputs($event.target);
        }

        // ctrl + enter optionally + shift
        if (keyCode == 13 && $event.originalEvent.getModifierState('Control')) {
            let shiftIsPressed = $event.originalEvent.getModifierState('Shift');
            return iterateOverRows($event.target, shiftIsPressed);
        }

        // enter
        if (keyCode == 13) {
            return $scope.runSearch()
        }

        // shift + del
        if (keyCode == 46 && $event.originalEvent.getModifierState('Shift')) {
            return $scope.removePart();
        }

        // shift + =
        if (keyCode == 187 && $event.originalEvent.getModifierState('Shift')) {
            return $scope.addEmptyPart();
        }
    }

    $scope.pasteProcess = function ($event, $index, type) {
        $event.stopPropagation();
        $event.stopImmediatePropagation();

        $timeout(function () {
            $scope.parsePaste(angular.element($event.target).val(), $index, type);
        }, 10);
    };

    $scope.hideResults = function () {
        return !$scope.parts || $scope.parts.length === 0
    };

    $scope.addEmptyPart = function () {
        $scope.parts.push({PartNumber: '', Qty: 0});
    };

    $scope.removePart = function ($index) {
        //possibly triggered by hotkey
        if (typeof $index !== "number") {
            $index = $(document.activeElement).parents('.part-info').data('index');

            // hotkey triggered without focused part info, nothing to delete
            if (typeof $index !== "number") {
                return;
            }
        }

        $scope.parts.splice($index, 1);
    };

    $scope.resetFilters = function () {
        $scope.filters = {
            show_prices: true,
            brands: [{
                id: 145,
                BrandName: 'TOYOTA'
            }], //toyota
            search_inside: false
        };
    };

    $scope.resetParts = function () {
        $scope.$emit('parts:clear');

        $scope.parts = [];
        for (let i = 0; i < 10; i++) {
            $scope.addEmptyPart();
        }
    };

    $scope.addBrand = function (brand) {
        $scope.filters.brands.push(brand);
        $scope.lookup = '';

        $scope.runSearch();
    };

    $scope.removeBrand = function (index) {
        $scope.filters.brands.splice(index, 1);

        $scope.runSearch();
    };

    $scope.$on('parts:add_to_dp', function (event, data) {
        Parts.addToDp(data, function (response) {
            if (response.success) {
                Message.pushSuccess('Parts added to order daily pool!', 2000);
                $scope.parts.forEach(function () {

                });
            }
        })
    });

    $timeout(attachScrollEvent, 200);

    Parts.rights({}, function (data) {
        $scope.user = data.user;
    });
}
