angular.module('cruiser').directive('backOrderCheck', BackOrderCheckDirective)

BackOrderCheckDirective.$inject = ['$controller', 'backOrderService']

function BackOrderCheckDirective($controller, backOrderService) {
  return {
    restrict: 'EA',
    scope: {
      type: '@'
    },
    templateUrl: '/app/modules/cruiser/templates/backOrder.html',
    link: function ($scope) {
      $scope.updateOrders = () => {
        backOrderService.all().then(data => {
          $scope.orders = data
        })
      }

      $scope.deleteOrderLocally = order => {
        const orderId = order.id
        const quote_id = order.quote_id || "";

        if($scope.orders[quote_id]){
          $scope.orders[quote_id].items = $scope.orders[quote_id].items.filter(order => order.id !== orderId)
        }
      }

      $scope.updateOrders()

      $scope.getStatusIconClass = (status_id) => {
        let status = 'mail'
        switch (status_id) {
          case 2: {
            status = 'order'
            break
          }
          case 3: {
            status = 'reorder'
          }
        }
        return `custom ${status}`
      }

      $scope.getSentClass = order => {
        return order.warehouse_qty > 0 ? 'green-color-light' : ''
      }

      $controller('orderStatus', {$scope: $scope})
    }
  }
}
