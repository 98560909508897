angular.module('cruiser').directive('researchForm', ResearchFormDirective);

ResearchFormDirective.$inject = [];

function ResearchFormDirective() {
	return {
		restrict:   "EA",
		replace:    true,
		transclude: true,
		templateUrl: "/app/modules/cruiser/templates/researchForm.html",

	}
}