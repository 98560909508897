angular.module('cruiser').controller('QuoteCtrl', QuoteController);

QuoteController.$inject = ['$scope', '$rootScope', 'Quotes', '$localStorage'];

function QuoteController($scope, $rootScope, Quotes, $localStorage) {
	$scope.openNewQuote = function(){
		let items = $scope.prepareQuoteItems();
		if(items.length === 0){
			return;
		}

		let totalPrice = items.map(i => i.price * i.qty)
			.reduce((a, b) => a + b);

		let WeightPhysical_total = items.map(function(i){
			return i.WeightPhysical
		}).reduce(function(a, b){
			return a + b;
		});

		let WeightVolumetric_total = items.map(function(i){
			return i.WeightVolumetric
		}).reduce(function(a, b){
			return a + b;
		});

		$scope.addQuoteToStorage({
			items: items,
			total: totalPrice,
			grand_total: totalPrice,
			WeightPhysical_total: WeightPhysical_total,
			WeightVolumetric_total: WeightVolumetric_total,
		});

		let href = '';

		if($scope.selectedQuote){
			href = '/' + $scope.selectedQuote + '?merge'
		}

		return window.location.href = '/quote' + href;
	};

	$scope.prepareQuoteItems = function(useOnlyRequiredQty = true){
		const quoteItems = [];
		$scope.parts.forEach(function(part){
			$scope.preparePartForQuote(part, quoteItems, useOnlyRequiredQty);
		});

		return quoteItems;
	};

	$scope.addItemsToDailyPool = function(){
		if(confirm('Are you sure want to add selected part to daily part?')){
			$rootScope.$broadcast('parts:add_to_dp', {parts: $scope.prepareQuoteItems()})
		}
	};

	$scope.select = function (item) {
		if(item.price){
			item.selected = !item.selected
		}
	};

	// TODO: call this function without 2nd param on ng-change/ng-click on checkboxes
	$scope.preparePartForQuote = function(part, quoteItems = [], useOnlyRequiredQty = true){
		let requiredQty = Number(part.Qty); // indicates how many parts still required

		function tryAddToQuote(partOnWarehouse, whName){
			let partsTakenHere;

			if (!partOnWarehouse) {return;}

			partOnWarehouse.partsTaken = null; // reset quantity indicator for warehouse on each "Quote" button press

			if (!requiredQty || !partOnWarehouse.selected || !partOnWarehouse.stock_qty) {return;}

			if(useOnlyRequiredQty){
				partsTakenHere = requiredQty
			} else {
				partsTakenHere = Math.min(partOnWarehouse.stock_qty, requiredQty); // take not more than missing quantity
			}

			quoteItems.push({
				part_id             : part.id,
				PartNumber          : part.PartNumber.toUpperCase(),
				WeightPhysical      : part.WeightPhysical,
				WeightVolumetric    : part.WeightVolumetric,
				DescriptionEnglish  : part.DescriptionEnglish,
				price               : partOnWarehouse.price,
				price_provider_raw  : partOnWarehouse.price_provider_raw,
				qty                 : partsTakenHere,
				WhName              : whName,
			});
			partOnWarehouse.partsTaken = partsTakenHere; // remember how many parts were taken from this warehouse
			requiredQty -= partsTakenHere; // reduce required quantity
		}

		// warehouses processed in the same order they are displayed in table
		// Canada
		tryAddToQuote((part.stock_ca || {}).CA, 'CA');
		// primary
		$scope.warehouses.primary.forEach(function(warehouse){
			tryAddToQuote((part.stock_data_primary || {})[warehouse.WhName], warehouse.WhName);
		});
		// USA
		tryAddToQuote((part.stock_usa || {}).USA, 'USA');
		// secondary
		$scope.warehouses.secondary.forEach(function(warehouse){
			tryAddToQuote((part.stock_data_secondary || {})[warehouse.WhName], warehouse.WhName);
		});

		if (requiredQty > 0) {
			// TODO: show missing quantity somewhere
		}
	};

	$scope.addQuoteToStorage = function(quoteItems){
		$scope.$storage.quotes.push(quoteItems);
	};

	Quotes.list({}, function(data){
		$scope.quotes = data;
	});

	$scope.$storage = $localStorage.$default({
		quotes: []
	});
}
