angular.module('manager.clients')
	.service("MngClientModal", MngClientModal);

MngClientModal.$inject = ['uiSimpleModal'];

function MngClientModal (uiSimpleModal) {

	return {
		open: function(client){
			let title = 'Edit client';
			let windowClass = 'mng-client-edit-modal';

			if(!client){
				client = {
					id: 0,
					name: '',
					email: '',
					phone: '',
					country: '',
					cars: [{
						id: 0,
						model: '',
						model_date: '',
						vin: ''
					}]
				};
				title = 'Save client';
				windowClass += ' create-client';
			}

			return uiSimpleModal({
				title:        title,
				size: 'lg',
				windowClass:  windowClass,
				templateUrl:  '/app/modules/clients/templates/clientModal.html',
				buttons: 	  ['save', 'cancel'],
				returnData:   true,
				wrapTemplate: false,
				controller:  'clientModalCtrl',
				data:         {
					client: client,
					removeCar: function ($index, data) {
						if(confirm('Are you sure?')){
							data.client.cars.splice($index, 1)
						}
					},
					addCar: function(data){
						data.client.cars.push({id: 0, model: '', model_date: '', vin: ''})
					}
				}
			});
		}
	};
}