angular.module('cruiser').service('deleteItem', deleteserv);

deleteserv.$inject = ['uiSimpleModal']

function deleteserv(uiSimpleModal) {

    return {
        open: arr => {
            let title = 'Delete items';
            let windowClass = 'Delete modal';

            let dataItem = {
                password:'',
                confirm_password:'',
                arr:arr
            };

            return uiSimpleModal({
                title: title,
                // size: 'lg',
                windowClass: windowClass,
                templateUrl: '/app/modules/cruiser/modals/delete/single.html',
                controller: 'deleteservCtrl',
                returnData: true,
                wrapTemplate: false,
                data: {
                    ...dataItem
                }
            })
        }
    }
}