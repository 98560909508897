
angular.module('manager.clients')
	.directive("mngClientListItem", MngClientListItem);

MngClientListItem.$inject = ['ClientsServer', 'Loading', 'Message', 'uiSimpleModal', 'MngClientModal', '$rootScope'];

function MngClientListItem(ClientsServer, Loading, Message, uiSimpleModal, MngClientModal, $rootScope) {
	return {
		restrict: 'EA',
		replace: true,
		templateUrl: '/app/modules/clients/templates/listItem.html',
		scope:{
			client: '='
		},
		link: function ($scope, elem, attrs, ctrl) {
			
			$scope.changeCredit = function changeCredit($event){
				uiSimpleModal({
					title: 'Are you sure?',
					text: 'Credit for this client will be ' + ($scope.client.has_credit ? 'disabled' : 'enabled'),
					titleIcon: 'svg-icon icon-warning',
					size: 'sm',
					showTitleClose: false,
					buttons: ['yes', 'no']
				}).then(function(result){
					if(result === 'yes'){
						Loading.show(
							'Editing client',
							'Please wait'
						);
						ClientsServer.toggleCredit({id: $scope.client.id}, function(client){
							$scope.client = client;
						});
					}
				});

				$event.stopPropagation();
			};
			
			$scope.edit = function edit(userData){
				if(!userData){
					userData = $scope.client;
				}

				MngClientModal.open(userData).then(function(result){
					if(result.button != 'save'){return;}

					Loading.show(
						'Saving changes',
						'Please wait'
					);

					ClientsServer.save({client: result.data.client}, function(client){
						Message.pushSuccess('Client saved!', 2000);

						$scope.client = client;
					}, function(response){
						$scope.edit(result.data.client);
					});
				});
			};
			
			$scope.delete = function(client){
				uiSimpleModal({
					title: 'Are you sure?',
					text: 'Client will be deleted!',
					titleIcon: 'svg-icon icon-warning',
					size: 'sm',
					showTitleClose: false,
					buttons: ['yes', 'no']
				}).then(function(result){
					if(result === 'yes'){
						Loading.show(
							'Deleting user',
							'Please wait'
						);

						ClientsServer.delete({id: client.id}, function(){
							Message.pushSuccess('Client was successfully deleted!', 2000);
							$rootScope.$broadcast('clients:list');
						});
					}
				});

			}
		}
	}
}

