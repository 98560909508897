angular.module('cruiser').directive('quote', QuoteDirective)

QuoteDirective.$inject = ['Quotes', 'ClientsServer', '$localStorage', 'Message', '$timeout', 'Loading', 'MngClientModal']

function QuoteDirective(Quotes, ClientsServer, $localStorage, Message, $timeout, Loading, MngClientModal) {
  return {
    restrict: 'EA',
    // replace:     true,
    scope: {
      type: '@'
    },
    templateUrl: '/app/modules/cruiser/templates/quote.html',
    link: function ($scope) {

      $scope.quote = {
        items: [],
        id: 0,
        WeightPhysical_total: 0,
        WeightVolumetric_total: 0,
        taxes: 0,
        total: 0,
        grand_total: 0,
        conversion_rate: 1,
        client_id: 0,
        car_id: 0
      }

      $scope.recalculateTotals = () => {
        if ($scope.quote.items.length > 0) {
          const totalPrice = $scope.quote.items
            .map(i => i.price * i.qty)
            .reduce((a, b) => a + b)

          const WeightPhysical_total = $scope.quote.items
            .map(i => i.WeightPhysical * i.qty)
            .reduce((a, b) => a + b)

          const WeightVolumetric_total = $scope.quote.items
            .map(i => i.WeightVolumetric * i.qty)
            .reduce((a, b) => a + b)

          $scope.quote.WeightVolumetric_total = parseFloat(WeightVolumetric_total.toFixed(2))
          $scope.quote.WeightPhysical_total = parseFloat(WeightPhysical_total.toFixed(2))
          $scope.quote.total = parseFloat(totalPrice.toFixed(2))
        }

        $scope.quote.grand_total = parseFloat((
          $scope.quote.total
          + ($scope.quote.taxes || 0)
          + ($scope.quote.shipping || 0)
          - ($scope.quote.discount || 0)
        ).toFixed(2))
      }

      $scope.removePart = function ($index) {
        if ($scope.quote.items[$index].DescriptionEnglish && !confirm('Are you sure?')) {
          return
        }

        $scope.quote.items.splice($index, 1)
        $scope.recalculateTotals()
      }

      $scope.setQuoteDraft = function (index) {
        if ($scope.quote.id && !window.confirm('Unsaved changes in existing quote will be lost! Are you sure?')) {
          return
        }

        $scope.currentQuoteIndex = index
        $scope.quote = $scope.$storage.quotes[index]
        $scope.history = []
      }

      $scope.pasteImage = function ($event, $index) {
        let clipboardItem = $event.originalEvent.clipboardData.items[0]
        let type = clipboardItem.type

        let reader = new FileReader()
        // if it's an image add it to the image field
        if (type.indexOf('image') === -1) {
          return
        }

        // get the image content and create an img dom element
        reader.readAsDataURL(clipboardItem.getAsFile())
        reader.onload = () => {
          let blobUrl = reader.result

          let img = $('#item-' + $index)
          img.attr('src', blobUrl)

          $scope.quote.items[$index].image = ''
          $scope.quote.items[$index].image_blob = blobUrl
          $scope.quote.items[$index].paste_dummy = ''
        }

        $timeout(function () {
          $('#image-paste-dummy-' + $index).blur()
        }, 100)
      }

      $scope.removeImage = function ($index) {
        if (!window.confirm('Are you sure?')) {
          return
        }

        $scope.quote.items[$index].image_remove = true
        $scope.quote.items[$index].image_blob = ''
        $scope.quote.items[$index].image = ''
      }

      $scope.setQuoteHistory = function ($index) {
        if ($index === -1) {
          return $scope.quote = $scope.originalQuote
        }

        $scope.quote = $scope.history[$index]
      }

      $scope.save = function () {
        Loading.show('Saving quote!', 'Please stand by')

        Quotes.save({quote: $scope.quote}, function (data) {
          Loading.hide()

          if (!data.id) {
            return
          }

          $scope.quote = data
          $scope.history = data.history

          if (typeof $scope.currentQuoteIndex === 'number') {
            $scope.$storage.quotes.splice($scope.currentQuoteIndex, 1)
          }

          Message.pushSuccess('Quote successfully saved', 2000)
        })
      }

      $scope.createInvoice = function () {
        if (!window.confirm('Are you sure? Also make sure you saved quote before creating invoice!')) {
          return
        }

        Loading.show('Creating Paypal invoice!', 'Please wait')

        Quotes.invoice({quote: $scope.quote}, function (data) {
          Loading.hide()

          $scope.quote.payment = data.payment
          Message.pushSuccess('Invoice url: ' + data.payment.payment_url, 2000)
        })
      }

      $scope.addEmptyPart = function () {
        $scope.quote.items.push({
          DescriptionEnglish: '',
          PartNumber: '',
          WhName: '',
          qty: 0,
          price: 0,
          price_provider_raw: 0,
          aftermarket: '',
          comment: '',
          WeightPhysical: 0,
          WeightVolumetric: 0,
        })
      }

      $scope.setClient = function ($item) {
        setClient($item)

        $scope.quote.car = $item.cars[0]
        $scope.quote.car_id = $item.cars[0] ? $item.cars[0].id : null

        $scope.setTaxes()
      }

      $scope.addClient = function () {
        MngClientModal.open().then(function (data) {
          ClientsServer.save({client: data.data.client}, function (client) {
            setClient(client)
          })
        })
      }

      $scope.editClient = function () {
        MngClientModal.open($scope.quote.client).then(function (data) {
          ClientsServer.save({client: data.data.client}, function (client) {
            setClient(client)
          })
        })
      }

      function setClient(client) {
        $scope.quote.client = client
        $scope.quote.client_id = client.id

        if (client.country && client.country.code === 'CA') {
          $scope.quote.currency = 'CAD'
        } else {
          $scope.quote.currency = 'USD'
        }
      }

      $scope.clearClient = function () {
        if (!confirm('Are you sure?')) {
          return
        }

        $scope.quote.client = null
        $scope.quote.currency = 'USD'
        $scope.quote.client_id = null
      }

      $scope.setCar = () => {
        $scope.quote.car = $scope.quote.client.cars.filter(i => {
          return i.id === $scope.quote.car_id
        })[0]
      }

      $scope.addCar = () => {
        MngClientModal.open($scope.quote.client).then(data => {

          ClientsServer.save({client: data.data.client}, () => {
            $scope.quote.car = data.client.cars[data.client.cars.length - 1]
            $scope.quote.car_id = $scope.quote.car ? $scope.quote.car.id : null
          })
        })
      }

      $scope.clientAutocomplete = function (val) {
        return ClientsServer.autocomplete({q: val}).$promise.then(function (data) {
          return data
        })
      }

      $scope.setTaxes = function () {
        let percentage = ($scope.quote.client && $scope.quote.client.state ? $scope.quote.client.state.tax_percentage : 0) / 100

        $scope.quote.taxes = parseFloat(((
          +$scope.quote.total
          + ($scope.quote.shipping || 0)
          - ($scope.quote.discount || 0)
        ) * percentage).toFixed(2))

        $scope.recalculateTotals()
      }

      $scope.setShipping = function () {
        switch ($scope.quote.shipping_select) {
          case 'free': {
            return $scope.quote.shipping = 0
          }

          case 'tbd': {
            return $scope.quote.shipping = null
          }
        }

        $scope.recalculateTotals()
      }

      $scope.$storage = $localStorage.$default({
        quotes: []
      })

      let initialQuote
      try {
        const quoteEl = $('#quote')[0]
        initialQuote = eval('window.initialQuote = ' + quoteEl.innerText)
      } catch (e) {

      }

      let lastCreatedQuoteIndex = $scope.$storage.quotes.length - 1
      let lastCreatedQuote = $scope.$storage.quotes[lastCreatedQuoteIndex]

      if (initialQuote) {
        $scope.originalQuote = initialQuote
        $scope.quote = initialQuote

        if ($scope.type === 'invoice') {
          $scope.quote.is_history = true
        }

        $scope.history = initialQuote.history
      } else {
        $scope.quote = lastCreatedQuote
        $scope.currentQuoteIndex = lastCreatedQuoteIndex
      }

      if (window.location.search === '?merge') {
        $scope.quote.items = $scope.quote.items.concat(lastCreatedQuote.items)
        $scope.$storage.quotes.splice(lastCreatedQuoteIndex, 1)
      }

      $scope.setTaxes()
      $scope.recalculateTotals()

      Quotes.rate({}, function (data) {
        $scope.quote.conversion_rate = $scope.quote.conversion_rate || data.rate
      })
    }
  }
}
