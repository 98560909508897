angular.module('cruiser').directive('warehouses', WarehousesDirective);

WarehousesDirective.$inject = [];

function WarehousesDirective() {
	return {
		restrict:    "E",
		replace:     true,
		scope:       {
			filters: "=",
			warehouses: '=',
			parts: '=',
			preparePartForQuote: "=",
			stockType: "@",
			class: "@"
		},
		templateUrl: "/app/modules/cruiser/templates/warehouses.html",
		link:        function ($scope) {
			$scope.select = function (item) {
				if(item && item.price){
					item.selected = !item.selected
				}
			}
		}
	}
}