angular.module('news')
	.service("MngNewsModal", MngNewsModal);

MngNewsModal.$inject = ['uiSimpleModal'];

function MngNewsModal (uiSimpleModal) {
	return {
		open: function(client){
			let title = 'Edit news';
			let windowClass = 'mng-client-edit-modal';

			if(!client){
				client = {

				};
				title = 'Save news';
				windowClass += ' Save news';
			}

			return uiSimpleModal({
				title:        title,
				size: 'lg',
				windowClass:  windowClass,
				templateUrl:  '/app/modules/cruiser/templates/news/newsModal.html',
				buttons: 	  ['save', 'cancel'],
				returnData:   true,
				wrapTemplate: false,
				controller:  'newsModalController',
				data:         {
					client: client,
					removeCar: function ($index, data) {
						if(confirm('Are you sure?')){
							data.client.cars.splice($index, 1)
						}
					},
					addCar: function(data){
						data.client.cars.push({id: 0, model: '', model_date: '', vin: ''})
					}
				}
			});
		}
	};
}
