angular.module('cruiser').factory('Parts', PartsService)

PartsService.$inject = ['$resource', 'ServerInterceptor']

function PartsService($resource, ServerInterceptor) {
  return $resource('/parts/search', {'id': '@id'}, {
    'search': {
      'url': '/parts/search',
      'method': 'post',
      'interceptor': ServerInterceptor
    },
    'research': {
      'url': '/parts/research',
      'method': 'post',
      'interceptor': ServerInterceptor
    },
    'warehouses': {
      'url': '/parts/warehouses',
      'method': 'get',
      'interceptor': ServerInterceptor
    },
    'brands': {
      'url': '/parts/brands',
      'method': 'get',
      'interceptor': ServerInterceptor
    },
    'chart': {
      'url': '/parts/charts/:id',
      'method': 'get',
      'interceptor': ServerInterceptor
    },
    'addToDp': {
      'url': '/parts/add_to_daily_pool',
      'method': 'post',
      'interceptor': ServerInterceptor
    },
    'rights': {
      'url': '/parts/rights',
      'method': 'get',
      'interceptor': ServerInterceptor
    },
    'csv': {
      'url': '/parts/csv',
      'method': 'post',
      isArray: true,
      'interceptor': ServerInterceptor
    },
    'orders': {
      'url': '/parts/orders',
      'method': 'get',
      'interceptor': ServerInterceptor,
    },
  })
}
