angular.module('cruiser').controller('orderStatus', OrderStatusController)

OrderStatusController.$inject = ['$scope', 'backOModal', 'backOrderService', 'Message']

function OrderStatusController($scope, backOModal, backOrderService, Message) {

  const openProcess = []

  $scope.addOrder = detailId => {
    if (detailId) {
      // заглушка чтобы не было несколько всплывающих окон
      if (openProcess[detailId]) {
        return
      }
      openProcess[detailId] = true
      backOrderService.findByDetailId(detailId).then(data => {
        openProcess[detailId] = false
        openModal(data)
      })
    } else {
      openModal()
    }
  }

  $scope.editOrder = order => {
      if (order.id) {
          // заглушка чтобы не было несколько всплывающих окон
          if (openProcess[order.id]) {
              return
          }
          openProcess[order.id] = true
          backOrderService.findById(order.id).then(data => {
              openProcess[order.id] = false
              openModal(data)
          })
      }
  }

  $scope.deleteOrder = order => {
     const orderId = order.id

      if (confirm(`Are you sure want to delete order: ${orderId}`)) {
      backOrderService.deleteOrder(orderId).then(() => {
          Message.pushSuccess('Order has been deleted successfully', 2000)
          $scope.deleteOrderLocally && $scope.deleteOrderLocally(order)
        },
        reason => {
          Message.pushFailure('Error when tried to delete order:', reason)
        })
    }
  }

  function openModal(detailId) {
    backOModal.open(detailId)
      .then(result => {
          const {order} = result.data

          const orderParams = {
            id: order.id,
            back_order_status_id: order.back_order_status_id,
            part_id: order.part_number.part_id,
            car_id: order.car_id,
            qty: order.qty,
            qty_received: order.qty_received,
            part_number: order.part_number.PartNumber,
            quote_id: order.quote && order.quote.id,
            client_id: order.client_id,
            customer_name: order.clientName,
            description: order.part_number.DescriptionEnglish,
            price: order.price,
          }

          if (order.is_stock) {
            orderParams.car_id = null
            orderParams.quote_id = null
            orderParams.client_id = null
            orderParams.customer_name = null
          }

          if(order.id === 0){ // если у нас не редактирование
              orderParams.warehouse_qty = 0
              orderParams.archived = false
              orderParams.sent = false
          }

          backOrderService.saveBackOrder(orderParams)
            .then(response => {
              if (response && response.id > 0) {
                Message.pushSuccess('Order added to back order check', 2000)
                $scope.updateOrders && $scope.updateOrders()
              }
            })
        },
        reason => {
          console.log('Отказ', reason)
        })
  }
}
