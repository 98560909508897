angular.module('manager.clients').controller('clientModalCtrl', ClientModalController);

ClientModalController.$inject = ['$scope', '$uibModalInstance', '$controller', 'config', 'ClientsServer'];

function ClientModalController($scope, $uibModalInstance, $controller, config, ClientsServer) {
	var parentCtrl = $controller('SimpleModalCtrl', {
		$scope: $scope,
		$uibModalInstance: $uibModalInstance,
		config: config
	});

	ClientsServer.countries({}, function(data){
		$scope.countries = data;

		if($scope.data.client.country_code){
			$scope.setCountry();
		}
	});

	$scope.setCountry = function () {
		let selectedCountry = $scope.countries.filter(function(c){
			return c.code == $scope.data.client.country_code;
		});

		if(selectedCountry[0]){
			$scope.data.client.country = selectedCountry[0];
		}
	};
}